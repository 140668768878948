import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBasket,
    IconUserCheck,
    IconUsers,
    IconMessages,
    IconMail,
    IconCalendar,
    IconNfc,
    IconMapPin,
    IconMap,
    IconMap2,
    IconFirstAidKit,
    IconSpeakerphone,
    IconMapPins,
    IconFlag3,
    IconListDetails,
    IconFriends
} from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconBasket,
    IconUsers,
    IconMessages,
    IconMail,
    IconCalendar,
    IconNfc,
    IconMapPin,
    IconMap,
    IconMap2,
    IconFirstAidKit,
    IconSpeakerphone,
    IconMapPins,
    IconFlag3,
    IconListDetails,
    IconFriends
};

// ===========================|| APPLICATION MENU ITEMS ||=========================== //

const pmpApplication = {
    id: 'application',
    title: <FormattedMessage id="application" />,
    type: 'group',
    children: [
        // {
        //     id: 'successStories',
        //     title: 'यशोगाथा',
        //     type: 'collapse',
        //     icon: icons.IconSpeakerphone,
        //     children: [
        //         {
        //             id: 'yashogatha',
        //             title: 'यशोगाथा',
        //             type: 'item',
        //             url: '/yashogatha',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'sankalp',
            title: <FormattedMessage id="menu.sankalp" />,
            type: 'collapse',
            icon: icons.IconMapPins,
            url: '/sankalp/ahawal',
            children: [
                {
                    id: 'add-sankalp',
                    title: <FormattedMessage id="menu.sankalp.addmultisankalp" />,
                    type: 'item',
                    url: '/sankalp/addmultisankalp',
                    breadcrumbs: false
                },
                {
                    id: 'sankalp-list',
                    title: <FormattedMessage id="menu.sankalp.list" />,
                    type: 'item',
                    url: '/sankalp/list',
                    breadcrumbs: false
                }
                // {
                //     id: 'sankalp-ahawal',
                //     title: <FormattedMessage id="menu.sankalp.ahawal" />,
                //     type: 'item',
                //     url: '/sankalp/ahawal',
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'sadyashtiti',
            title: <FormattedMessage id="menu.sadyashtiti_label" />,
            type: 'collapse',
            icon: icons.IconFlag3,
            url: '/sadyashiti/ahawal',
            children: [
                {
                    id: 'sadyasthiti-list',
                    title: <FormattedMessage id="menu.sadyashtiti_view_list" />,
                    type: 'item',
                    url: '/sadyashiti/list',
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'sanghik',
        //     title: <FormattedMessage id="menu.sanghik" />,
        //     type: 'collapse',
        //     icon: icons.IconUsers,
        //     children: [
        //         {
        //             id: 'add-sanghik',
        //             title: <FormattedMessage id="menu.sanghik.add" />,
        //             type: 'item',
        //             url: '/sanghik/add',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'list-sanghik',
        //             title: <FormattedMessage id="menu.sanghik.list" />,
        //             type: 'item',
        //             url: '/sanghik/ahawal',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'swayamsuchak',
            title: <FormattedMessage id="menu.swayamsuchak" />,
            type: 'collapse',
            icon: icons.IconListDetails,
            children: [
                // {
                //     id: 'ahawal-list',
                //     title: <FormattedMessage id="menu.ahawal.list" />,
                //     type: 'item',
                //     url: '/ahawal/list',
                //     breadcrumbs: false
                // }
                {
                    id: 'add-suchi',
                    title: <FormattedMessage id="menu.suchi.list" />,
                    type: 'item',
                    url: '/swayamsuchak/list',
                    breadcrumbs: false
                },
                {
                    id: 'view-ahawal',
                    title: <FormattedMessage id="menu.report" />,
                    type: 'item',
                    url: '/swayamsuchak/report',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'seva-vasti',
            title: <FormattedMessage id="menu.seva-vasti" />,
            type: 'collapse',
            icon: icons.IconFirstAidKit,
            children: [
                // {
                //     id: 'add-sevavasti',
                //     title: <FormattedMessage id="menu.seva-vasti.add" />,
                //     type: 'item',
                //     url: '/seva-vasti/add',
                //     breadcrumbs: false
                // },
                {
                    id: 'sevavasti-list',
                    title: <FormattedMessage id="menu.seva-vasti.list" />,
                    type: 'item',
                    url: '/seva-vasti/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'bhaugolik-rachana',
            title: <FormattedMessage id="bhaugolik-rachana" />,
            type: 'collapse',
            icon: icons.IconMap2,
            children: [
                // {
                //     id: 'add-system-user',
                //     title: <FormattedMessage id="bhaugolik-rachana-add" />,
                //     type: 'item',
                //     url: '/customer/customer-list',
                //     breadcrumbs: false
                // },
                {
                    id: 'system-users-list',
                    title: <FormattedMessage id="bhaugolik-rachana-view" />,
                    type: 'item',
                    url: '/geo/tree',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'system-users',
            title: <FormattedMessage id="system-users" />,
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'add-system-user',
                    title: <FormattedMessage id="add-system-user" />,
                    type: 'item',
                    url: '/user/add',
                    breadcrumbs: false
                },
                {
                    id: 'system-users-list',
                    title: <FormattedMessage id="system-users-list" />,
                    type: 'item',
                    url: '/user/list',
                    breadcrumbs: false
                },
                {
                    id: 'unapproved-users-list',
                    title: <FormattedMessage id="menu.approve" />,
                    type: 'item',
                    url: '/user/unapproved',
                    breadcrumbs: false
                }
            ]
        }
        // {
        //     id: 'ahawal',
        //     title: <FormattedMessage id="menu.ahawal" />,
        //     type: 'collapse',
        //     icon: icons.IconMapPins,
        //     children: [
        //         {
        //             id: 'ahawal-list',
        //             title: <FormattedMessage id="menu.ahawal.list" />,
        //             type: 'item',
        //             url: '/ahawal/list',
        //             breadcrumbs: false
        //         }
        //         // {
        //         //     id: 'add-sankalp',
        //         //     title: <FormattedMessage id="menu.ahawal.list-2" />,
        //         //     type: 'item',
        //         //     url: '/sankalp/add',
        //         //     breadcrumbs: false
        //         // }
        //     ]
        // },
        // {
        //     id: 'lokjagaran-abhiyan-vrutta',
        //     title: <FormattedMessage id="menu.lokjagaran-abhiyan-vrutta" />,
        //     type: 'collapse',
        //     icon: icons.IconFriends,
        //     children: [
        //         {
        //             id: 'la-bhaugolik-vrutta',
        //             title: <FormattedMessage id="menu.bhaugolik" />,
        //             type: 'item',
        //             url: '/PMP-LJAV/bhaugolik-vrutta',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'la-samajik-vrutta',
        //             title: <FormattedMessage id="menu.samajik" />,
        //             type: 'item',
        //             url: '/PMP-LJAV/samajik-vrutta',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'la-sahayogi-sanstha-mahiti-sankalan',
        //             title: <FormattedMessage id="menu.sahayogi-sanstha-mahiti-sankalan" />,
        //             type: 'item',
        //             url: '/PMP-LJAV/sahayogi-sansth-mahiti',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'la-vividh-kshetrache-vrutt',
        //             title: <FormattedMessage id="menu.vividh-kshetrache-vrutt" />,
        //             type: 'item',
        //             url: '/PMP-LJAV/vividh-kshetra-vrutta',
        //             breadcrumbs: false
        //         }
        //     ]
        // }

        // {
        //     id: 'ramlalla-pratishtha-samparka-abhiyan',
        //     title: <FormattedMessage id="menu.ramlalla-pratishtha-samparka-abhiyan" />,
        //     type: 'collapse',
        //     icon: icons.IconMapPins,
        //     children: [
        //         {
        //             id: 'rp-abhiyan-vrutta',
        //             title: <FormattedMessage id="menu.rp-abhiyan" />,
        //             type: 'item',
        //             url: '/PMP-RPSA/abhiyan-vrutta',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'rp-karyakram-vrutta',
        //             title: <FormattedMessage id="menu.rp-karyakram" />,
        //             type: 'item',
        //             url: '/PMP-RPSA/karyakram-vrutta',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'rp-karyakarta-suchi',
        //             title: <FormattedMessage id="menu.rp-karyakartaSuchi" />,
        //             type: 'item',
        //             url: '/PMP-RPSA/karyakarta-suchi',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'rp-anukulVyakti-suchi',
        //             title: <FormattedMessage id="menu.rp-anukulVyaktiSuchi" />,
        //             type: 'item',
        //             url: '/PMP-RPSA/anukulVyakti-suchi',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'rp-abhiyan-goshwara',
        //             title: <FormattedMessage id="menu.rp-abhiyanGoshwara" />,
        //             type: 'item',
        //             url: '/PMP-RPSA/abhiyan-goshwara',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'rp-karyakram-goshwara',
        //             title: <FormattedMessage id="menu.rp-karyakramGoshwara" />,
        //             type: 'item',
        //             url: '/PMP-RPSA/karyakram-goshwara',
        //             breadcrumbs: false
        //         }
        //     ]
        // }

        // {
        //     id: 'chat',
        //     title: <FormattedMessage id="chat" />,
        //     type: 'item',
        //     icon: icons.IconMessages,
        //     url: '/app/chat'
        // },
        // {
        //     id: 'mail',
        //     title: <FormattedMessage id="mail" />,
        //     type: 'item',
        //     icon: icons.IconMail,
        //     url: '/app/mail'
        // },
        // {
        //     id: 'calendar',
        //     title: <FormattedMessage id="calendar" />,
        //     type: 'item',
        //     url: '/app/calendar',
        //     icon: icons.IconCalendar,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'contact',
        //     title: <FormattedMessage id="contact" />,
        //     type: 'collapse',
        //     icon: icons.IconNfc,
        //     children: [
        //         {
        //             id: 'c-card',
        //             title: <FormattedMessage id="cards" />,
        //             type: 'item',
        //             url: '/app/contact/c-card',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'c-list',
        //             title: <FormattedMessage id="list" />,
        //             type: 'item',
        //             url: '/app/contact/c-list',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'e-commerce',
        //     title: <FormattedMessage id="e-commerce" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'products',
        //             title: <FormattedMessage id="products" />,
        //             type: 'item',
        //             url: '/e-commerce/products'
        //         },
        //         {
        //             id: 'product-details',
        //             title: <FormattedMessage id="product-details" />,
        //             type: 'item',
        //             url: '/e-commerce/product-details/default',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-list',
        //             title: <FormattedMessage id="product-list" />,
        //             type: 'item',
        //             url: '/e-commerce/product-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'checkout',
        //             title: <FormattedMessage id="checkout" />,
        //             type: 'item',
        //             url: '/e-commerce/checkout'
        //         }
        //     ]
        // }
    ]
};

export default pmpApplication;
